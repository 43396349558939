import { AbsoluteContainer, ValiditySpinner } from "../common-styling";

const AbsoluteLoader = ({ loaderMessage }) => {
  return (
    <AbsoluteContainer>
      <ValiditySpinner
        animation="border"
        variant="secondary"
        className="ms-2 rounded-circle"
      />
      <small className="ms-2">{loaderMessage}</small>
    </AbsoluteContainer>
  );
};
export default AbsoluteLoader;
