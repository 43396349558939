import { useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { loginUser } from "../../services/api-declaration";
import { showError, showSuccess } from "../../services/toast-service";
import { login } from "../../store/slices/auth";
import AuthTemplate from "./auth-template";
import OTPMethod from "./otp-method";
import AbsoluteLoader from "../loader/absolute-loader";
import { MULTI_FACTOR_TYPES } from "../strings";
import { INITITIAL_LOADING_DATA } from "../common-confgs/common-vars";

const OTPLogin = ({ email, password, authenticators = [] }) => {
  const [loadingData, setLoadingData] = useState(INITITIAL_LOADING_DATA);
  const inputsRef = useRef([]);
  const dispatch = useDispatch();

  const handleLoginWithOtp = async (formValues) => {
    try {
      setLoadingData({ isLoading: true, loadingText: "Logging In.." });
      const response = await loginUser({ ...formValues, email, password });
      dispatch(login(response.data));
      showSuccess("Sie haben sich erfolgreich angemeldet!");
    } catch (error) {
      console.log(error);
      showError(error.response?.data?.message || "Etwas ist schief gelaufen!");
    } finally {
      inputsRef.current.forEach((input) => {
        input.disabled = false;
        input.value = "";
      });
      setLoadingData(INITITIAL_LOADING_DATA);
    }
  };
  return (
    <div className="position-relative">
      <AuthTemplate className="" width={400} title="Anmelden">
        <OTPMethod
          email={email}
          onSubmit={handleLoginWithOtp}
          inputsRef={inputsRef}
          loadingData={loadingData}
          setLoadingData={setLoadingData}
          authenticators={authenticators}
          defaultAuthType={
            authenticators.length
              ? MULTI_FACTOR_TYPES.EMAIL
              : MULTI_FACTOR_TYPES.AUTHENTICATOR
          }
        />
      </AuthTemplate>
      {loadingData.isLoading && loadingData.loadingText && (
        <AbsoluteLoader loaderMessage={loadingData.loadingText} />
      )}
    </div>
  );
};

export default OTPLogin;
