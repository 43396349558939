import { useRef, useState } from "react";
import { Card, Col, Row, Spinner } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { getAllAdCreativesEvaluation } from "../../services/api-declaration";
import DateSelectorToolbar from "../date-range-pickers/date-selector-toolbar";
import { showError } from "../../services/toast-service";
import AdCard from "../ads-evaluation/ad-card";
import { CustomCard } from "../common-styling";
import Loader from "../loader/loader";
import {
  adCreativeApiDateRange,
  DATE_FILTERS,
  PICKER_LAYOUT,
  PICKER_OPT_AD_CREATIVE
} from "../date-range-pickers/picker-helper";
import InfiniteScroll from "react-infinite-scroll-component";
import { getFormattedNumber } from "../../services/common-service";

const FunnelAdsEvaluation = ({ funnelId, funnelVersion }) => {
  const { t } = useTranslation();

  const [isLoading, setIsLoading] = useState(true);
  const [paginateOpts, setPaginateOpt] = useState({});
  const [adCreativeData, setAdCreativeData] = useState([]);
  const [totalSpent, setTotalSpent] = useState(0);
  const dateFilterValuesRef = useRef({});

  const fetchAdCreativesEvaluation = async (
    dateFilterValues,
    isInfiniteScroll
  ) => {
    try {
      setAdCreativeData([]);
      setIsLoading(true);
      const response = await getAllAdCreativesEvaluation({
        pageIndex: isInfiniteScroll ? paginateOpts.page + 1 : 1,
        funnelId,
        funnelVersion,
        ...adCreativeApiDateRange(dateFilterValues)
      });
      const respData = response.data.data;
      if (!respData.data.length) {
        setPaginateOpt({});
        return;
      }

      if (isInfiniteScroll) {
        setAdCreativeData((d) => [...d, ...respData.data]);
      } else {
        setAdCreativeData(respData.data);
      }
      setPaginateOpt({
        totalPages: Math.ceil(
          respData.metadata.total / respData.metadata.limit
        ),
        page: respData.metadata.page,
        hasPrevPage: respData.metadata.hasPrevPage,
        hasNextPage: respData.metadata.hasNextPage,
        totalDocs: respData.metadata.total,
        rowsOnPage: respData.metadata.length
      });
      setTotalSpent(respData.metadata.totalSpent);
    } catch (error) {
      setPaginateOpt({});
      console.log("ERROR: ", error);
      showError(
        error.response?.data.message ||
          t("toastMessage.errorInGetting", { what: "Ad Creatives" })
      );
    } finally {
      dateFilterValuesRef.current = dateFilterValues;
      setIsLoading(false);
    }
  };

  return (
    <CustomCard className="mb-3 p-3 rounded-0 pt-1">
      <Card.Header
        style={{ top: -4, zIndex: 1000 }}
        className="bg-white mb-4 d-flex justify-content-between sticky-top"
      >
        <h5 className="align-self-center m-0">
          {t("adEvaluation.creativeOverview")}
          <span className="blockquote-footer">{`${t(
            "funnelInsights.adEval.totalBudget"
          )}: ${getFormattedNumber(totalSpent)}€`}</span>
        </h5>
        <DateSelectorToolbar
          options={PICKER_OPT_AD_CREATIVE}
          fetchData={fetchAdCreativesEvaluation}
          layout={PICKER_LAYOUT.DROPDOWN}
          defaultSelected={DATE_FILTERS.L30D}
          minDate={"2024-01-27"}
        />
      </Card.Header>
      {isLoading && !adCreativeData.length ? (
        <Loader colSpan={8} />
      ) : !adCreativeData.length ? (
        <h6 className="mb-0 fw-bold p-5 align-self-center">{t("noData")}</h6>
      ) : (
        <div style={{ height: 500 }}>
          <InfiniteScroll
            height={510}
            scrollThreshold={0.5}
            dataLength={adCreativeData.length}
            next={() => {
              fetchAdCreativesEvaluation(dateFilterValuesRef.current, true);
            }}
            hasMore={paginateOpts.hasNextPage || false}
            endMessage={
              <p style={{ textAlign: "center" }}>
                <b>Yay! You have seen it all</b>
              </p>
            }
            loader={
              <div className="d-flex justify-content-center">
                <Spinner animation="border" />
              </div>
            }
          >
            <Row>
              {/* <Loader calcHeight={10} /> */}
              {adCreativeData.map((data, index) => (
                <Col md={2} sm={6} xs={12} className="my-2" key={index}>
                  <AdCard data={data} />
                </Col>
              ))}
            </Row>
          </InfiniteScroll>
        </div>
      )}
    </CustomCard>
  );
};
export default FunnelAdsEvaluation;
