import { CustomCard } from "../../common-styling";

const InfoCard = ({ cardHeading, label, value, children }) => (
  <CustomCard className="p-3 mt-3">
    <h6 className="mb-2">{cardHeading}</h6>
    <div className="ms-2 mt-2">
      <div className="p-2 bg-light mb-2 d-flex text-secondary">
        <div className="fw-bold me-2">{label}:</div>
        {value}
      </div>
      {children}
    </div>
  </CustomCard>
);

export default InfoCard;
