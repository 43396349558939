// import { useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import { ListGroup, ListGroupItem } from "react-bootstrap";
import InfoCard from "./info-card";
import { useTranslation } from "react-i18next";

const FbInfo = () => {
  const { t } = useTranslation();
  const { getValues } = useFormContext();

  return (
    <div>
      {/* user info*/}
      <InfoCard
        cardHeading={t("userDetails")}
        label="Email"
        value={getValues("facebookForm.user")?.email}
      />

      {/* page info*/}
      <InfoCard
        cardHeading={t("pageDetails")}
        label={t("common.name")}
        value={getValues("facebookForm.page.name")}
      />
      {/* form info*/}
      {getValues("facebookForm.forms")?.map((form, idx) => (
        <InfoCard
          cardHeading={t("formDetails")}
          label={t("common.name")}
          value={form.name}
          key={idx}
        >
          <div className="p-2 bg-light mb-2 text-secondary">
            <div className="fw-bold me-2">{t("question", { count: 2 })}:</div>
            <ListGroup className="m-2">
              {form.questions?.map((question, idx) => (
                <ListGroupItem
                  key={question.id}
                  className={`border-0 ps-2 ${
                    idx !== form.questions?.length - 1 && "border-bottom"
                  }`}
                >
                  <b className="me-2">{idx + 1}.</b>
                  {question.label}
                </ListGroupItem>
              ))}
            </ListGroup>
          </div>
        </InfoCard>
      ))}
    </div>
  );
};

export default FbInfo;
