import styled from "styled-components";
import Offcanvas from "react-bootstrap/Offcanvas";

const OffcanvasContainer = styled(Offcanvas)`
  background: #f3f6f9;
`;
const TestModeText = styled.span`
  font-size: 14px;
`;
const DrawerHeading = styled.h4`
  color: var(--secondary-text);
`;

const Drawer = ({
  showDrawer,
  toggleDrawer,
  drawerTitle,
  children,
  isTestMode = false,
  width = "50"
}) => {
  return (
    <OffcanvasContainer
      placement="end"
      className={`w-${width}`}
      show={showDrawer}
      onHide={toggleDrawer}
      scroll={true}
    >
      <Offcanvas.Header className="pb-0" closeButton>
        <Offcanvas.Title>
          <DrawerHeading className="fw-bold">
            {drawerTitle}{" "}
            <TestModeText className="text-primary">
              {isTestMode && "(TEST MODE)"}
            </TestModeText>
          </DrawerHeading>
        </Offcanvas.Title>
      </Offcanvas.Header>
      <Offcanvas.Body>{children}</Offcanvas.Body>
    </OffcanvasContainer>
  );
};

export default Drawer;
