import { Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { faCopy } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { showSuccess } from "../../services/toast-service";

const CopyToClipboardButton = ({
  linkToCopy,
  customClasses = "",
  customStyles = {}
}) => {
  const { t } = useTranslation();
  const copyToClipboard = () => {
    navigator.clipboard
      .writeText(linkToCopy)
      .then(() => showSuccess(t("clipboardCopySuccess")));
  };
  return (
    <Button
      size="sm"
      onClick={copyToClipboard}
      variant="outline-secondary"
      style={customStyles}
      className={customClasses}
    >
      <FontAwesomeIcon icon={faCopy} />
    </Button>
  );
};
export default CopyToClipboardButton;
