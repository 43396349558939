import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import {
  TableRow,
  CustomCard,
  FirstHeaderColumn,
  HeaderColumn,
  LastHeaderColumn,
  BootstrapTable,
  TableDetailCell,
  PageHeading,
  TableWrap
} from "../common-styling";
import TableLoader from "../loader/table-loader";
import NoResultsRow from "../no-results-table-row";
import { showError, showSuccess } from "../../services/toast-service";
import CustomPagination from "../pagination/custom-pagination";
import {
  fetchMissingInfoCount,
  fetchMissingLeadsInfo,
  updateMissingLeadInfo,
  ignoreMissingLeadInfo
} from "../../services/api-declaration";
import { useTranslation } from "react-i18next";
import { useOptionsFetch } from "../../hooks/useOptionsFetch";
import AsyncSelectField from "../reusable-form-fields/async-select";
import { useForm } from "react-hook-form";
import { Button, Form } from "react-bootstrap";
import { setMissingInfoCount } from "../../store/slices/missingInfo";
import { useDispatch } from "react-redux";

const MissingLeadInfo = () => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(true);
  const [btnLoading, setBtnLoading] = useState({});
  const [leads, setLeads] = useState([]);
  const [paginateOpts, setPaginateOpts] = useState({});
  const [searchParams, setSearchParams] = useSearchParams();
  const [animateItem, setAnimateItem] = useState("");

  const dispatch = useDispatch();
  const { handleFunnelOptions } = useOptionsFetch();

  const handleQueryParams = (property, value) => {
    searchParams.set(property, value);
    setSearchParams(searchParams);
  };

  const methods = useForm({
    defaultValues: {}
  });
  const { control, watch } = methods;

  async function* commonFunctionality(key, body, cbFunc) {
    const loadingState = {};
    loadingState[key] = "load";
    setBtnLoading({ ...loadingState });
    const funnelData = watch(key);
    try {
      [body, cbFunc] = yield funnelData;
      const response = await cbFunc(body);
      if (response?.data) {
        setAnimateItem(body.formId);
      }
      showSuccess(response.data.message);
      loadingState[key] = "hide";
    } catch (error) {
      showError(
        error?.response?.data?.message ||
          t("toastMessage.errorInUpdating", {
            what: t("common.lead")
          })
      );
      loadingState[key] = "";
    } finally {
      setBtnLoading({ ...loadingState });
      try {
        const resp = await fetchMissingInfoCount();
        const countData = resp.data.data;
        dispatch(setMissingInfoCount(countData));
      } catch (error) {
        console.log("error", error);
      }
    }
  }

  const handleSave = async (data, key) => {
    const seq = commonFunctionality(key);
    const { value: funnelData } = await seq.next();
    const body = {
      funnelId: funnelData._id,
      formId: data.form_id,
      pageId: data.page_id
    };
    await seq.next([body, updateMissingLeadInfo]);
  };

  const handleIgnore = async (data, key) => {
    const seq = commonFunctionality(key);
    await seq.next().value;
    const body = {
      formId: data.form_id
    };
    await seq.next([body, ignoreMissingLeadInfo]);
  };

  const getOptionLabel = (option) =>
    `${option.general?.funnelName} (${option.general?.funnelType})`;

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        setIsLoading(true);
        const params = {};
        for (const [key, value] of searchParams.entries()) {
          params[key] = value;
        }
        const response = await fetchMissingLeadsInfo(params);
        const respData = response.data.data;
        if (!respData.data.length) {
          return;
        }
        setLeads(respData.data);
        setPaginateOpts({
          totalPages: Math.ceil(
            respData.metadata.total / respData.metadata.limit
          ),
          hasPrevPage: respData.metadata.hasPrevPage,
          hasNextPage: respData.metadata.hasNextPage,
          totalDocs: respData.metadata.total,
          rowsOnPage: respData.metadata.length
        });
      } catch (error) {
        showError(
          error?.response?.data?.message ||
            t("toastMessage.errorInGetting", {
              what: t("common.lead", { count: 2 })
            })
        );
      } finally {
        setIsLoading(false);
      }
    };
    fetchUsers();
    // eslint-disable-next-line
  }, [searchParams]);

  useEffect(() => {
    if (animateItem) {
      setTimeout(() => {
        setPaginateOpts((prevPage) => ({
          ...prevPage,
          totalDocs: prevPage.totalDocs - 1,
          rowsOnPage: prevPage.rowsOnPage - 1
        }));
        setLeads((prevLeads) =>
          prevLeads.filter((lead) => lead.form_id !== animateItem)
        );
      }, 300);
    }
  }, [animateItem]);

  return (
    <>
      <div>
        <PageHeading className="">{t("Missing Lead Info")}</PageHeading>

        <CustomCard className="mt-4 mb-3 p-3 rounded-0">
          <Form>
            <TableWrap>
              <BootstrapTable responsive>
                <thead>
                  <tr>
                    <FirstHeaderColumn>{"Form"}</FirstHeaderColumn>
                    <HeaderColumn>{"Page"}</HeaderColumn>
                    <HeaderColumn>{t("missingInfo.leadCount")}</HeaderColumn>
                    <HeaderColumn>
                      {t("missingInfo.lead.selectFunnel")}
                    </HeaderColumn>
                    <LastHeaderColumn />
                  </tr>
                </thead>
                <tbody>
                  {isLoading ? (
                    <TableLoader colSpan={8} />
                  ) : leads.length ? (
                    leads.map((data) => {
                      const key = `funnel-${data.form_id}`;
                      return (
                        <TableRow
                          className={
                            animateItem === data.form_id ? "slide-out" : ""
                          }
                          key={key}
                        >
                          <TableDetailCell className="py-3">
                            {data.form_name || data.form_id}
                          </TableDetailCell>
                          <TableDetailCell className="py-3">
                            {data.page_name || data.page_id}
                          </TableDetailCell>
                          <TableDetailCell className="py-3">
                            {data.leadCount}
                          </TableDetailCell>
                          <TableDetailCell
                            style={{ overflow: "visible" }}
                            className="py-3"
                          >
                            <Form.Group>
                              {data.funnelId ? (
                                <div>{data.funnel?.name}</div>
                              ) : (
                                <AsyncSelectField
                                  control={control}
                                  name={key}
                                  paramName="funnelName"
                                  showDefaultOpts={false}
                                  getOptionLabel={getOptionLabel}
                                  fetchData={(ev) =>
                                    handleFunnelOptions({
                                      ...ev,
                                      fbPageId: data.page_id
                                    })
                                  }
                                  isDisabled={data.funnelId}
                                />
                              )}
                            </Form.Group>
                          </TableDetailCell>
                          <TableDetailCell className="text-center align-middle py-3">
                            {btnLoading[key] === "hide" || data.funnelId ? (
                              <>PENDING</>
                            ) : (
                              <div className="d-flex gap-3 justify-content-center">
                                <Button
                                  disabled={
                                    !watch(key) || btnLoading[key] === "load"
                                  }
                                  variant="primary"
                                  onClick={() => handleSave(data, key)}
                                >
                                  {btnLoading[key] === "load"
                                    ? "Saving..."
                                    : "Save"}
                                </Button>
                                <Button
                                  disabled={btnLoading[key] === "load"}
                                  variant="outline-danger"
                                  onClick={() => handleIgnore(data, key)}
                                >
                                  {t("common.ignore")}
                                </Button>
                              </div>
                            )}
                          </TableDetailCell>
                        </TableRow>
                      );
                    })
                  ) : (
                    <NoResultsRow lengthOfColumns="5" />
                  )}
                </tbody>
              </BootstrapTable>
            </TableWrap>
          </Form>
        </CustomCard>
        {Object.values(paginateOpts).length ? (
          <CustomPagination
            paginateOpts={paginateOpts}
            activeTab={Number(searchParams.get("pageIndex")) || 1}
            select={(val) => handleQueryParams("pageIndex", val)}
          />
        ) : (
          <></>
        )}
      </div>
    </>
  );
};

export default MissingLeadInfo;
