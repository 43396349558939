import Form from "react-bootstrap/Form";

const TextArea = ({
  label,
  name,
  placeholder,
  register,
  rows = "4",
  noSpacing = false
}) => {
  return (
    <Form.Group controlId={label} className={!noSpacing ? "mb-3" : ""}>
      <Form.Label>{label}</Form.Label>

      <Form.Control
        {...register(name)}
        rows={rows}
        as="textarea"
        placeholder={placeholder}
      />
    </Form.Group>
  );
};
export default TextArea;
