import {
  fbSortOptions,
  fbSortOrderOptions,
  formTypeOptions,
  insightLeadSortOptions,
  adsEvalSortOptions,
  sourceOptions,
  statusOptions,
  userRoleOptions,
  userStatusOptions
} from "../table-filter/static-filter-opts";

const customer = {
  type: "asyncFilter",
  label: "common.customer",
  name: "customer",
  queryName: "customer",
  getOptionLabel: (option) =>
    `${option.firstName} ${option.lastName} (${option.email})`
};
const created = {
  type: "dateRangeFilter",
  label: "common.created",
  name: "dateRange"
};
const fbCreated = {
  type: "fbDateRangeFilter",
  label: "common.created",
  name: "dateRange"
};
const category = {
  type: "asyncFilter",
  label: "common.category",
  name: "category",
  queryName: "category"
};
const fbSortBy = {
  type: "staticFilter",
  label: "common.sortBy",
  name: "sortby",
  options: fbSortOptions
};
const insightLeadSortBy = {
  type: "staticFilter",
  label: "common.sortBy",
  name: "sortby",
  options: insightLeadSortOptions
};
const adsEvalSortBy = {
  type: "staticFilter",
  label: "common.sortBy",
  name: "sortby",
  options: adsEvalSortOptions
};
const fbSortOrder = {
  type: "staticFilter",
  label: "common.sortOrder",
  name: "sortorder",
  options: fbSortOrderOptions
};
const minMaxRange = {
  type: "minMaxRangeFilter",
  label: "advancedFilter.priceRange",
  name: "minmax"
};

export const funnelFilterConfig = [
  category,
  customer,
  created,
  {
    type: "staticFilter",
    label: "advancedFilter.formType",
    name: "formType",
    options: formTypeOptions
  }
];

export const leadFilterConfig = [
  customer,
  {
    type: "asyncFilter",
    label: "advancedFilter.funnel",
    name: "funnel",
    queryName: "funnel",
    paramName: "funnelName",
    getOptionLabel: (option) =>
      `${option.general?.funnelName} (${option.general?.funnelType})`
  },
  created,
  {
    type: "staticFilter",
    label: "advancedFilter.status",
    name: "status",
    options: statusOptions
  },
  {
    type: "staticFilter",
    label: "common.source",
    name: "source",
    options: sourceOptions
  },
  {
    type: "checkBox",
    label: "advancedFilter.archive",
    name: "archive"
  }
];

export const upcomingLeadFilterConfig = [
  customer,
  {
    type: "asyncFilter",
    label: "advancedFilter.funnel",
    name: "funnel",
    queryName: "funnel",
    paramName: "funnelName",
    getOptionLabel: (option) =>
      `${option.general?.funnelName} (${option.general?.funnelType})`
  },
  created
];

export const galleryFilterConfig = [
  category,
  {
    type: "asyncFilter",
    label: "advancedFilter.tags",
    name: "tags",
    queryName: "tags",
    options: sourceOptions,
    isMulti: true
  }
];

export const userFilterConfig = [
  {
    type: "staticFilter",
    label: "common.role",
    name: "role",
    options: userRoleOptions
  },
  {
    type: "staticFilter",
    label: "advancedFilter.userStatus",
    name: "userStatus",
    options: userStatusOptions
  }
];

export const FEInsightLeadFilterConfig = [
  category,
  customer,
  created,
  {
    type: "staticFilter",
    label: "advancedFilter.leadStatus",
    name: "leadstatus",
    options: statusOptions
  },
  {
    type: "staticFilter",
    label: "common.leadSource",
    name: "leadsource",
    options: sourceOptions
  },
  {
    type: "checkBox",
    label: "advancedFilter.archive",
    name: "archive"
  },
  insightLeadSortBy,
  fbSortOrder
];

export const FEInsightConversionFilterConfig = [
  category,
  customer,
  created,
  insightLeadSortBy,
  fbSortOrder
];

export const FEFilterConfig = [
  category,
  // customer,
  fbCreated,
  fbSortBy,
  fbSortOrder
];

export const AdsEvaluationFilterConfig = [
  category,
  fbCreated,
  adsEvalSortBy,
  fbSortOrder,
  minMaxRange
];
