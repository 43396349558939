import {
  Row,
  Col,
  Card,
  Spinner,
  ButtonGroup,
  ToggleButton
} from "react-bootstrap";
import moment from "moment";
import styled from "styled-components";
import { useSelector, useDispatch } from "react-redux";
import { useCallback, useEffect, useState } from "react";
import AreaChart from "../charts/areaChart";
import { ReportStatistics } from "./report-statistics";
import { CustomCard, PerformanceCardContainer } from "../common-styling";
import { getCustomerReportByFunnelId } from "../../services/api-declaration";
import { setFunnelFilterMode } from "../../store/slices/performanceReportQueryParams";
import { useTranslation } from "react-i18next";
import "./performance-card.css";

const CardTitle = styled.h5`
  color: var(--text-gray-muted);
  font-weight: bold;
`;

const Container = styled.div`
  width: 100%;
  height: 287px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const RADIO_VALUE = {
  WEEK: "L7D",
  FORTNIGHT: "L14D",
  MONTH: "L30D"
};

const RADIOS = [
  { label: "advancedFilter.dateRange.days7", value: RADIO_VALUE.WEEK },
  { label: "advancedFilter.dateRange.days14", value: RADIO_VALUE.FORTNIGHT },
  { label: "advancedFilter.dateRange.days30", value: RADIO_VALUE.MONTH }
];

export const PerformanceCard = ({ report, customerId, funnelId }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { funnelParams, generalParams } = useSelector(
    (state) => state.performanceReportQueryParams
  );
  const [radioValue, setRadioValue] = useState(
    funnelParams[funnelId]
      ? funnelParams[funnelId].filterMode
      : RADIO_VALUE.FORTNIGHT
  );
  const [performance, setPerformance] = useState({ [radioValue]: report });
  const [series, setSeries] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const generateSeries = useCallback(
    (rawData) => {
      const range =
        radioValue === RADIO_VALUE.WEEK
          ? 7
          : radioValue === RADIO_VALUE.FORTNIGHT
            ? 14
            : 30;
      const startDate = new Date(moment().subtract(range, "day").toISOString());
      const end = new Date(moment().subtract(1, "day").toISOString());
      const graphSeries = [];
      for (let dt = startDate; dt <= end; dt.setDate(dt.getDate() + 1)) {
        const currDate = new Date(dt).toISOString().split("T")[0];
        let data = rawData.find((log) => log.date === currDate) || 0;
        if (data) {
          data = data.count;
        }
        graphSeries.push({
          x: new Date(dt).toISOString().split("T")[0],
          y: data
        });
      }
      return graphSeries;
    },
    [radioValue]
  );

  const handleRadioSelect = (value) => {
    setRadioValue(value);
  };

  const fetchCustomerPerformance = useCallback(async () => {
    const queryParams = { filterMode: radioValue, ...generalParams };
    dispatch(setFunnelFilterMode({ funnelId, value: radioValue }));
    try {
      setIsLoading(true);
      const response = await getCustomerReportByFunnelId(
        customerId,
        funnelId,
        queryParams
      );
      const respData = response.data.data;
      setPerformance((perf) => ({
        ...perf,
        [radioValue]: respData || {}
      }));
    } catch (error) {
      console.log("ERROR: ", error);
    } finally {
      setIsLoading(false);
    }
  }, [funnelId, customerId, radioValue, dispatch, generalParams]);

  useEffect(() => {
    if (!performance[radioValue]) {
      fetchCustomerPerformance();
    }
  }, [radioValue, performance, fetchCustomerPerformance]);

  useEffect(() => {
    if (
      performance[radioValue]?.leadCounts &&
      !series.hasOwnProperty.call(series, radioValue)
    ) {
      const data = generateSeries(performance[radioValue].leadCounts);
      setSeries({
        ...series,
        [radioValue]: performance[radioValue].leadCounts.length ? data : []
      });
    }
  }, [series, performance, radioValue, generateSeries]);

  return (
    <PerformanceCardContainer className="p-3 mb-4 rounded-4">
      <Card.Header className="bg-white d-flex justify-content-between align-items-center">
        <div>
          <CardTitle className="mb-0 fw-bold leads-header text-[var(--text-gray-muted)]">
            {t("common.funnel")}
          </CardTitle>
          <h4 className="mb-0 fw-bold leads-header">{report.funnelName}</h4>
        </div>
        <div className="performance--filter-div">
          <ButtonGroup>
            {RADIOS.map((radio, idx) => (
              <ToggleButton
                id={`radio-${idx + Math.floor(Math.random() * 10000)}`}
                size="lg"
                variant="dark"
                key={`date-radio-${idx}`}
                className={`date-filter--toggle ${
                  radioValue === radio.value && "active"
                }`}
                value={radio.value}
                type="radio"
                name="radio"
                checked={radioValue === radio.value}
                onChange={(e) => handleRadioSelect(e.currentTarget.value)}
              >
                {t(radio.label)}
              </ToggleButton>
            ))}
          </ButtonGroup>
        </div>
      </Card.Header>
      <Card.Body>
        {isLoading ? (
          <Container>
            <Spinner animation="border" />
          </Container>
        ) : (
          <Row className="align-items-center">
            <Col xs={12} lg={9} className="px-0">
              {series[radioValue]?.length ? (
                <AreaChart series={series[radioValue]} />
              ) : (
                <Container>
                  <CustomCard className="w-100 mx-3 mt-4 p-3 rounded-0">
                    <h6 className="mb-0 fw-bold p-5 align-self-center">
                      {t("noData")}
                    </h6>
                  </CustomCard>
                </Container>
              )}
            </Col>
            <Col xs={12} lg={3}>
              <ReportStatistics
                leadCount={performance[radioValue]?.totalLeadCount}
                costPerLead={performance[radioValue]?.costPerLead}
                spend={performance[radioValue]?.spend}
                costPerQualifiedLead={
                  performance[radioValue]?.costPerQualifiedLead
                }
                leadQualifiedCount={performance[radioValue]?.leadQualifiedCount}
              />
            </Col>
          </Row>
        )}
      </Card.Body>
    </PerformanceCardContainer>
  );
};
