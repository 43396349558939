import styled from "styled-components";
import Modal from "react-bootstrap/Modal";
import Image from "react-bootstrap/Image";
import { Col, Row } from "react-bootstrap";
import { faClose } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { CloseIconContainer } from "../common-styling";
import { useTranslation } from "react-i18next";

const Img = styled(Image)`
  width: 100%;
  height: 100%;
  border-radius: 6px;
`;
const DetailsContainer = styled.div`
  padding: 10px;
  border-top: 1px solid var(--th-border-color);
`;

const VerticallyCenteredModal = (props) => {
  const { data } = props;
  const { t } = useTranslation();

  return (
    <Modal {...props} size="lg" centered>
      <Img src={data.image || data.videoInfo?.picture || data.thumbnail} />
      <DetailsContainer className="p-3">
        <Row className="align-items-baseline">
          <Col lg={3}>
            <h5>{`${t("common.creative")} ${t("title")}`}:</h5>
          </Col>
          <Col>{data.creativeTitle}</Col>
        </Row>
        <Row className="align-items-baseline">
          <Col lg={3}>
            <h5>Ad Name:</h5>
          </Col>
          <Col>{data.ad_name}</Col>
        </Row>
        <Row className="align-items-baseline">
          <Col lg={3}>
            <h5>Adset Name:</h5>
          </Col>
          <Col>{data.adset_name}</Col>
        </Row>
        <Row className="align-items-baseline">
          <Col lg={3}>
            <h5>{`${t("campaign")} ${t("common.name")}`}:</h5>
          </Col>
          <Col>{data.campaign_name}</Col>
        </Row>
        <Row className="align-items-baseline">
          <Col lg={3}>
            <h5>Funnel:</h5>
          </Col>
          <Col>{data.funnelName}</Col>
        </Row>
        <Row className="align-items-baseline">
          <Col lg={3}>
            <h5>{t("common.customer")}:</h5>
          </Col>
          <Col>{data.customer}</Col>
        </Row>
      </DetailsContainer>
      <CloseIconContainer onClick={(e) => props.onHide(e.target.value)}>
        <FontAwesomeIcon icon={faClose} className="text-danger" />
      </CloseIconContainer>
    </Modal>
  );
};

const ImageDetailsDialog = ({ data, toggleModal }) => {
  return (
    <VerticallyCenteredModal data={data} show={true} onHide={toggleModal} />
  );
};
export default ImageDetailsDialog;
