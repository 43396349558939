import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarAlt } from "@fortawesome/free-solid-svg-icons";
import {
  USER_ROLES,
  USER_STATUS,
  LEAD_SOURCE,
  FORM_SOURCE_TYPE,
  LEAD_LOGS_STATUS_TYPE
} from "../strings";
import { Translation } from "react-i18next";

export const dateRangeOptions = [
  {
    name: (
      <div className="d-flex align-items-center justify-content-between">
        <div>
          <Translation>
            {(t) => t("advancedFilter.dateRange.custom")}
          </Translation>
        </div>
        <FontAwesomeIcon icon={faCalendarAlt} />
      </div>
    ),
    _id: "custom"
  },
  { name: "advancedFilter.dateRange.today", _id: "TD" },
  { name: "advancedFilter.dateRange.yesterday", _id: "YD" },
  { name: "advancedFilter.dateRange.thisWeek", _id: "TW" },
  { name: "advancedFilter.dateRange.lastWeek", _id: "LW" },
  { name: "advancedFilter.dateRange.thisMonth", _id: "TM" },
  { name: "advancedFilter.dateRange.lastMonth", _id: "LM" },
  { name: "advancedFilter.dateRange.thisYear", _id: "TY" },
  { name: "advancedFilter.dateRange.lastYear", _id: "LY" }
];

export const fbDateRangeOptions = [
  {
    name: (
      <div className="d-flex align-items-center justify-content-between">
        <div>
          <Translation>
            {(t) => t("advancedFilter.dateRange.custom")}
          </Translation>
        </div>
        <FontAwesomeIcon icon={faCalendarAlt} />
      </div>
    ),
    _id: "custom"
  },
  { name: "advancedFilter.dateRange.yesterday", _id: "YD" },
  { name: "advancedFilter.dateRange.last7D", _id: "BT_L7D" },
  { name: "advancedFilter.dateRange.last14D", _id: "BT_L14D" },
  { name: "advancedFilter.dateRange.last30D", _id: "BT_L30D" },
  { name: "advancedFilter.dateRange.last90D", _id: "BT_L90D" },
  { name: "advancedFilter.dateRange.thisYear", _id: "BT_TY" }
];

export const statusOptions = [
  { name: "Success", _id: LEAD_LOGS_STATUS_TYPE.SUCCESS },
  { name: "Fail", _id: LEAD_LOGS_STATUS_TYPE.FAIL }
  // { label: "Retry", value: LEAD_LOGS_STATUS_TYPE.RETRY },
];

export const formTypeOptions = [
  { name: "Cockpit", _id: FORM_SOURCE_TYPE.CP },
  { name: "Facebook", _id: FORM_SOURCE_TYPE.FB },
  {
    name: "Cockpit & Facebook",
    _id: FORM_SOURCE_TYPE.CP_FB
  }
];

export const sourceOptions = [
  { name: "Cockpit", _id: LEAD_SOURCE.CP },
  { name: "Facebook Cockpit", _id: LEAD_SOURCE.FB_CP },
  { name: "Facebook Ad", _id: LEAD_SOURCE.FB_AD },
  { name: "Homepage", _id: LEAD_SOURCE.HOMEPAGE },
  { name: "Uncategorised", _id: LEAD_SOURCE.UNCATEGORISED }
];

export const userStatusOptions = [
  { name: "Acitve", _id: USER_STATUS.ACTIVE },
  { name: "In-active", _id: USER_STATUS.IN_ACTIVE }
];

export const userRoleOptions = [
  { name: "Admin", _id: USER_ROLES.ADMIN },
  { name: "Agent", _id: USER_ROLES.AGENT }
];

export const fbSortOptions = [
  { name: "advancedFilter.fbSort.leadCount", _id: "leadcount" },
  { name: "advancedFilter.fbSort.costPerLead", _id: "cpl" },
  { name: "advancedFilter.fbSort.costPerQualified", _id: "cpql" },
  {
    name: "advancedFilter.fbSort.qualifiedCount",
    _id: "leadqualifiedcount"
  },
  { name: "advancedFilter.fbSort.click", _id: "clicks" },
  { name: "advancedFilter.fbSort.reach", _id: "reach" },
  { name: "advancedFilter.fbSort.impressions", _id: "impressions" },
  { name: "advancedFilter.fbSort.costPerClick", _id: "cpc" },
  { name: "advancedFilter.fbSort.spend", _id: "spend" }
];

export const insightLeadSortOptions = [
  { name: "advancedFilter.fbSort.leadCount", _id: "leadcount" }
];

export const adsEvalSortOptions = [
  { name: "advancedFilter.fbSort.leadCount", _id: "leadcount" },
  {
    name: "advancedFilter.fbSort.qualifiedCount",
    _id: "leadqualifiedcount"
  },
  { name: "advancedFilter.fbSort.costPerLead", _id: "cpl" },
  { name: "advancedFilter.fbSort.costPerQualified", _id: "cpql" }
];

export const fbSortOrderOptions = [
  { name: "advancedFilter.sortOrder.highestFirst", _id: "high" },
  { name: "advancedFilter.sortOrder.lowestFirst", _id: "low" }
];
