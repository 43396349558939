import {
  Tab,
  Tabs,
  Form,
  Button,
  Spinner,
  Collapse,
  ListGroup,
  ListGroupItem,
  Accordion
} from "react-bootstrap";
import Select from "react-select";
import { useRef, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { useFormContext, Controller, useWatch } from "react-hook-form";
import MenuList from "./menu-list";
import SelectUser from "./user-select";
import { SELECT_TAB } from "../../strings";
import { useTranslation } from "react-i18next";
import SelectField from "../../reusable-form-fields/select-field";
import ValidationMessage from "../../validation-message/validation-message";

const FbForm = ({
  fbLoader,
  fbOptions,
  getFbPages,
  getPageForms,
  setFbOptionsCb,
  isCustomFieldValidated,
  setIsCustomFieldValidated
}) => {
  const {
    control,
    setValue,
    getValues,
    watch,
    clearErrors,
    formState: { errors }
  } = useFormContext();
  const { t } = useTranslation();
  const oldValue = useRef();
  const pageIdRef = useRef();
  const container = useRef();
  const [activeTab, setActiveTab] = useState({
    page: SELECT_TAB.SEARCH,
    form: SELECT_TAB.SEARCH
  });

  const handleFbPageChangeCallback = async (newValue) => {
    if (newValue.id === oldValue.current) return;
    getPageForms(newValue.id);
    setValue("facebookForm.forms", null);

    oldValue.current = newValue.id;
  };
  // const handleFbFormChangeCallback = (value) => {
  //   const allForms = getValues("facebookForm.form");
  //   allForms.push(...value);
  //   setValue("facebookForm.form", allForms);
  // };
  const handleTabSelect = (_eventKey) => {
    setActiveTab({ ...activeTab, page: _eventKey });
    // set form value of page and form fields
    setValue("facebookForm.tabType", _eventKey);
    setValue("facebookForm.page", null);
    setValue("facebookForm.forms", null);
    oldValue.current = "";
    pageIdRef.current.value = "";
    // reset fb form select options
    setFbOptionsCb([], "forms");
  };

  const fbPageId = useWatch({
    name: `facebookForm.page.id`,
    control
  });

  const searchHandler = async () => {
    getPageForms(fbPageId);
    setValue(`facebookForm.forms`, null);
  };

  return (
    <>
      <div className="d-flex mt-2">
        <SelectUser isLoadingPages={fbLoader.pages} />
      </div>
      <Collapse in={Boolean(getValues("facebookForm.user")?._id)}>
        <Form.Group className="mb-4">
          <Form.Label
            className={`fw-bold ${errors?.facebookForm?.page && "text-danger"}`}
          >
            {t("selectFbPage")}:{errors?.facebookForm?.page && "*"}
          </Form.Label>
          <Tabs
            id="fb-page-selection-tabs"
            activeKey={activeTab.page}
            onSelect={(evKey) => {
              if (activeTab.page === evKey) return;
              handleTabSelect(evKey);
            }}
          >
            <Tab className="mt-2" title="Page" eventKey={SELECT_TAB.SEARCH}>
              <div className="d-flex align-items-center justify-content-end">
                <div className="d-flex align-items-center flex-fill me-2">
                  <Controller
                    control={control}
                    name="facebookForm.page"
                    render={({ field }) => (
                      <>
                        <div ref={container} className="w-100">
                          <Select
                            components={{
                              MenuList: (props) => (
                                <MenuList container={container} {...props} />
                              )
                            }}
                            className="w-100 text-capitalize"
                            {...field}
                            options={fbOptions.pages}
                            getOptionLabel={(option) => option.name}
                            getOptionValue={(option) => option.id}
                            isClearable={false}
                            onChange={(option, action) => {
                              field.onChange(option);
                              handleFbPageChangeCallback(option, action);
                            }}
                          />
                        </div>
                      </>
                    )}
                  />
                  {fbLoader.form && (
                    <Spinner
                      className="ms-3"
                      variant="primary"
                      animation="border"
                    />
                  )}
                </div>
                {!fbOptions.pages.length && !fbLoader.form && (
                  <Button
                    onClick={getFbPages}
                    variant="outline-primary"
                    disabled={fbLoader.pages || fbOptions.pages.length}
                  >
                    {t("getPages")}
                  </Button>
                )}
                {fbLoader.pages && (
                  <Spinner
                    className="ms-3"
                    variant="primary"
                    animation="border"
                  />
                )}
              </div>
              <ValidationMessage error={errors.facebookForm?.page?.message} />
              {!fbOptions.pages.length ? (
                <div className="d-flex align-items-center">
                  <FontAwesomeIcon
                    className="text-info fs-5 me-1"
                    icon={faInfoCircle}
                  />
                  <div className="text-muted small">
                    <span>{t("retrievePagesNote")}</span>
                  </div>
                </div>
              ) : null}
            </Tab>
            <Tab className="mt-2" title="Custom" eventKey={SELECT_TAB.CUSTOM}>
              <div className="d-flex align-items-center justify-content-end">
                <div className="flex-fill">
                  <Form.Control
                    type="text"
                    placeholder={`${t("common.enter")} ${t("facebookPgID")}`}
                    name="facebookForm.page.id"
                    ref={pageIdRef}
                    onChange={(e) => {
                      setValue("facebookForm.page.id", e.target.value);
                      setValue("facebookForm.forms", null);
                      setIsCustomFieldValidated(false);
                      clearErrors("facebookForm.page");
                    }}
                  />
                </div>
                <Button
                  className="align-self-start ms-2"
                  onClick={searchHandler}
                  variant="outline-primary"
                  disabled={fbLoader.page || !fbPageId}
                >
                  {t("common.search")}
                </Button>
                {fbLoader.page && (
                  <Spinner
                    className="ms-3"
                    variant="primary"
                    animation="border"
                  />
                )}
              </div>
              <ValidationMessage
                error={
                  errors?.facebookForm?.page?.id
                    ? errors.facebookForm?.page.id?.message
                    : errors.facebookForm?.page?.message
                }
              />
            </Tab>
          </Tabs>
        </Form.Group>
      </Collapse>
      <Collapse
        in={
          !fbLoader.form &&
          Boolean(getValues("facebookForm.page.id")) &&
          Boolean(fbOptions.forms.length) &&
          isCustomFieldValidated
        }
      >
        <div>
          <div className="mt-3">
            <Form.Group className="mb-4">
              <Form.Label
                className={`fw-bold ${
                  errors?.facebookForm?.forms && "text-danger"
                }`}
              >
                {t("selectFbForm")}:{errors?.facebookForm?.forms && "*"}
              </Form.Label>
              <SelectField
                control={control}
                name={`facebookForm.forms`}
                options={fbOptions.forms}
                isMulti
                optionValue="id"
                // onSelect={handleFbFormChangeCallback}
              />
              <ValidationMessage error={errors?.facebookForm?.forms?.message} />
            </Form.Group>
          </div>
          <Accordion defaultActiveKey={0}>
            {watch("facebookForm.forms")?.map((form_data, form_idx) => {
              return (
                <Accordion.Item key={form_idx} eventKey={form_idx}>
                  <Accordion.Header>
                    {form_data.name || "Facebook Form"}
                  </Accordion.Header>
                  <Accordion.Body>
                    <Collapse
                      in={
                        !fbLoader.form &&
                        Boolean(form_data.id) &&
                        Boolean(fbOptions.forms.length) &&
                        isCustomFieldValidated
                      }
                    >
                      <Form.Group className="mb-4">
                        <Form.Label className="fw-bold">
                          {t("formQuestions")}:
                        </Form.Label>
                        <ListGroup className="ms-2 mt-2">
                          {form_data.questions?.map((question, idx) => (
                            <ListGroupItem
                              className={`border-0 ps-2 ${
                                idx !== form_data.questions.length - 1
                                  ? "border-bottom"
                                  : ""
                              }`}
                              key={question.id}
                            >
                              <b className="me-2">{idx + 1}.</b>
                              {question.label}
                            </ListGroupItem>
                          ))}
                        </ListGroup>
                      </Form.Group>
                    </Collapse>
                  </Accordion.Body>
                </Accordion.Item>
              );
            })}
          </Accordion>
        </div>
      </Collapse>
    </>
  );
};
export default FbForm;
