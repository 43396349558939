import {
  BootstrapTable,
  CustomCard,
  FirstHeaderColumn,
  HeaderColumn,
  LastHeaderColumn,
  NameLink,
  PageHeading,
  TableDetailCell,
  TableRow,
  TableWrap
} from "../common-styling";
import TableLoader from "../loader/table-loader";
import NoResultsRow from "../no-results-table-row";
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import {
  fetchLeadsWithMissingUTM,
  fetchMissingInfoCount,
  updateLeadAdAssociation
} from "../../services/api-declaration";
import { useTranslation } from "react-i18next";
import CustomPagination from "../pagination/custom-pagination";
// import { generateDateTime } from "../../services/common-service";
import { FormProvider, useForm } from "react-hook-form";
import { Button, Form } from "react-bootstrap";
import TextField from "../reusable-form-fields/text-field";
import { showError, showSuccess } from "../../services/toast-service";
import { LEAD_AD_ASSOCIATION_STATUS } from "../strings";
import { setMissingInfoCount } from "../../store/slices/missingInfo";
import { useDispatch } from "react-redux";

const MissingUTMParamsInfo = () => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(true);
  const [leads, setLeads] = useState([]);
  const [paginateOpts, setPaginateOpts] = useState({});
  const [searchParams, setSearchParams] = useSearchParams();
  const [btnLoading, setBtnLoading] = useState(false);
  const [animateItem, setAnimateItem] = useState("");

  const dispatch = useDispatch();

  const handleQueryParams = (property, value) => {
    searchParams.set(property, value);
    setSearchParams(searchParams);
  };

  const methods = useForm({
    defaultValues: {}
  });
  const { register, watch } = methods;

  const handleUpdateAdAssociation = async (
    leadId,
    status,
    campaignNameField,
    campaignIdField,
    adIdField
  ) => {
    setBtnLoading(leadId);
    const campaignName = watch(campaignNameField);
    const campaignId = watch(campaignIdField);
    const adId = watch(adIdField);
    try {
      const body = {
        campaignName,
        campaignId,
        adId
      };
      const response = await updateLeadAdAssociation(leadId, status, body);
      if (response?.data) {
        setAnimateItem(leadId);
      }
      showSuccess(response.data.message);
    } catch (error) {
      showError(
        error?.response?.data?.message ||
          t("toastMessage.errorInGetting", {
            what: t("common.lead")
          })
      );
    } finally {
      setBtnLoading("");
    }
  };

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        setIsLoading(true);
        const params = {};
        for (const [key, value] of searchParams.entries()) {
          params[key] = value;
        }
        const response = await fetchLeadsWithMissingUTM(params);
        const respData = response.data.data;
        if (!respData.rows.length) {
          return;
        }
        setLeads(respData.rows);
        setPaginateOpts({
          totalPages: respData.totalPages,
          hasPrevPage: respData.hasPrevPage,
          hasNextPage: respData.hasNextPage,
          totalDocs: respData.totalDocs,
          rowsOnPage: respData.pageSize
        });
      } catch (error) {
        console.log(
          error?.response?.data?.message || t("toastMessage.errorInGetting")
        );
      } finally {
        setIsLoading(false);
        try {
          const resp = await fetchMissingInfoCount();
          const countData = resp.data.data;
          dispatch(setMissingInfoCount(countData));
        } catch (error) {
          console.log("error", error);
        }
      }
    };
    fetchUsers();
    // eslint-disable-next-line
  }, [searchParams]);

  useEffect(() => {
    if (animateItem) {
      setTimeout(() => {
        setPaginateOpts((prevPage) => ({
          ...prevPage,
          totalDocs: prevPage.totalDocs - 1,
          rowsOnPage: prevPage.rowsOnPage - 1
        }));
        setLeads((prevLeads) =>
          prevLeads.filter((lead) => lead.id !== animateItem)
        );
      }, 300);
    }
  }, [animateItem]);

  return (
    <div>
      <PageHeading>{t("Missing UTM Parameters Info")}</PageHeading>

      <CustomCard className="mt-4 mb-3 p-3 rounded-0">
        <FormProvider {...methods}>
          <Form>
            <TableWrap>
              <BootstrapTable responsive>
                <thead>
                  <tr>
                    <FirstHeaderColumn>
                      Funnel {t("common.name")}
                    </FirstHeaderColumn>
                    <HeaderColumn className="text-center align-center px-3">
                      Lead {t("common.name")}
                    </HeaderColumn>
                    {/* <HeaderColumn>
                      {t("common.created")} {t("common.on")}
                    </HeaderColumn> */}
                    <HeaderColumn>
                      {t("campaign")} {t("common.name")}
                    </HeaderColumn>
                    <HeaderColumn>{t("campaign")}-ID</HeaderColumn>
                    <HeaderColumn>{t("adDuplicator.viewIdLabel")}</HeaderColumn>
                    <LastHeaderColumn />
                  </tr>
                </thead>
                <tbody>
                  {isLoading ? (
                    <TableLoader colSpan={8} />
                  ) : leads.length ? (
                    leads.map((item, idx) => {
                      const campaignFieldName = `campaignName-${item._id}`;
                      const campaignIdFieldName = `campaignId-${item._id}`;
                      const adIdFieldName = `adId-${item._id}`;
                      return (
                        <TableRow
                          key={`missingUTMlead-${idx}`}
                          className={
                            animateItem === item._id ? "slide-out" : ""
                          }
                        >
                          <TableDetailCell className="w-25 py-3">
                            <NameLink
                              className="d-block"
                              to={`/dashboard/funnels/${item.funnelId?._id}/form?funnelType=${item.funnelId.general.funnelType}`}
                            >
                              {item.funnelId.general.funnelName}
                            </NameLink>
                          </TableDetailCell>
                          <TableDetailCell className="w-auto py-3 text-center align-center px-3">
                            {`${item.funnelId.customer?.firstName} ${item.funnelId.customer?.lastName}`}
                          </TableDetailCell>
                          {/* <TableDetailCell className="w-auto py-3">
                            {generateDateTime(item.createdAt)}
                          </TableDetailCell> */}
                          <TableDetailCell className="w-auto py-3">
                            <TextField
                              type="text"
                              name={campaignFieldName}
                              placeholder={`${t("common.enter")} ${t(
                                "campaign"
                              )} ${t("common.name")}`}
                              register={register}
                              noSpacing
                            />
                          </TableDetailCell>
                          <TableDetailCell className="w-auto py-3">
                            <TextField
                              type="text"
                              name={campaignIdFieldName}
                              placeholder={`${t("common.enter")} ${t(
                                "campaign"
                              )}-ID`}
                              register={register}
                              noSpacing
                            />
                          </TableDetailCell>
                          <TableDetailCell className=" w-auto py-3">
                            <TextField
                              type="text"
                              name={adIdFieldName}
                              placeholder={`${t("common.enter")} ${t(
                                "adDuplicator.viewIdLabel"
                              )}`}
                              register={register}
                              noSpacing
                            />
                          </TableDetailCell>
                          <TableDetailCell className="w-auto text-center align-middle py-3">
                            <div className="d-flex gap-3 justify-content-center">
                              <div className="w-50 d-flex justify-content-end">
                                <Button
                                  disabled={
                                    !watch(campaignFieldName) ||
                                    !watch(campaignIdFieldName) ||
                                    !watch(adIdFieldName) ||
                                    btnLoading === item._id
                                  }
                                  variant="primary"
                                  onClick={() =>
                                    handleUpdateAdAssociation(
                                      item?._id,
                                      LEAD_AD_ASSOCIATION_STATUS.HANDLED,
                                      campaignFieldName,
                                      campaignIdFieldName,
                                      adIdFieldName
                                    )
                                  }
                                >
                                  {btnLoading === item._id
                                    ? "Saving..."
                                    : "Save"}
                                </Button>
                              </div>
                              <Button
                                disabled={btnLoading === item._id}
                                variant="outline-danger"
                                onClick={() =>
                                  handleUpdateAdAssociation(
                                    item?._id,
                                    LEAD_AD_ASSOCIATION_STATUS.IGNORED,
                                    campaignFieldName,
                                    campaignIdFieldName,
                                    adIdFieldName
                                  )
                                }
                              >
                                {t("common.ignore")}
                              </Button>
                            </div>
                          </TableDetailCell>
                        </TableRow>
                      );
                    })
                  ) : (
                    <NoResultsRow lengthOfColumns="5" />
                  )}
                </tbody>
              </BootstrapTable>
            </TableWrap>
          </Form>
        </FormProvider>
      </CustomCard>
      {Object.values(paginateOpts).length ? (
        <CustomPagination
          paginateOpts={paginateOpts}
          activeTab={Number(searchParams.get("pageIndex")) || 1}
          select={(val) => handleQueryParams("pageIndex", val)}
        />
      ) : (
        <></>
      )}
    </div>
  );
};

export default MissingUTMParamsInfo;
